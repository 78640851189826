<template>
  <div>
    <span class="text-h5 text--darken-1 ">Schedule: </span>

    <ul>
      <li
        v-for="event in eventsFormated[postId]"
        :key="`event_${event.postId}`"
        class="eventBlock"
      >
        <p>
          <strong>{{ event.title }} </strong> -
          <font class="formattedDate">
            <font v-show="event.startDateTimeFormated">
              {{ event.startDateTimeFormated }}
            </font>
            <font v-show="event.endTimeFormated">
              -
              {{ event.endTimeFormated }}
            </font>
          </font>
        </p>
        <div
          style="padding-left:16px;color:rgb(87, 87, 87);margin-bottom: 10px;padding-bottom: 10px;border-bottom:1px solid grey;"
          v-show="expand && event.description"
          v-html="event.description"
        ></div>
      </li>
    </ul>

    <a
      v-show="canExpand"
      class="accent--text"
      style="text-decoration: underline !important;"
      @click="expand = !expand"
    >
      <font v-show="!expand"> Show Details</font>
      <font v-show="expand"> Hide Details</font>
    </a>
  </div>
</template>
<script>
export default {
  name: "eventTimeline",
  components: {},
  props: {
    appSettings: Object,
    width: String,
    postId: Number,
    eventsFormated: Object
  },
  computed: {
    canExpand() {
      let canExpand = false;

      if (
        this?.postId &&
        this?.eventsFormated &&
        this?.eventsFormated[this.postId]
      ) {
        this.eventsFormated[this.postId].forEach(event => {
          try {
            if (event?.description?.trim()) {
              canExpand = true;
            }
          } catch (e) {
            //do nothing;
          }
        });
      }

      return canExpand;
    }
  },
  data: () => ({
    expand: false
  }),

  methods: {
    contractView() {
      this.expand = false;
    }
  }
};
</script>
<style scoped>
ul {
  padding-left: 18px;
}
ul li {
  xpadding-left: 18px;
}
.formattedDate {
  display: inline-block;
}

.eventBlock p {
  line-height: 1.2;
  margin-bottom: 6px;
}
</style>
